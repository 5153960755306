<template>
  <div>
    <!--    <van-nav-bar title="排片参考" left-text="返回" left-arrow @click-left="toTopPage()">-->
    <!--    </van-nav-bar>-->
    <div>
      <filter-com
        :cinema-list="cinemas"
        v-if="cinemas.length > 1"
        @change="updateData"
      ></filter-com>
      <chart-v :datas="ringDatas" v-if="ringDatas.length > 0"></chart-v>
      <data-list
        :datas="groupDatas"
        :date="filterDate"
        v-if="groupDatas.length > 0"
      ></data-list>
      <van-empty v-else description="暂无数据"></van-empty>
    </div>
  </div>
</template>

<script>
import filterCom from './modules/filmArrangement/filter'
import chartV from './modules/filmArrangement/chartV'
import DataList from './modules/filmArrangement/list.vue'
import { zjApi } from '@/api/index'
import { Toast } from 'vant'
export default {
  name: 'warningMsg',
  components: {
    filterCom,
    chartV,
    DataList,
  },
  data() {
    return {
      filterDate: '',
      cinemas: ['全部'], //院线列表
      // 表格分组
      groupDatas: [],
      // 图形数据
      ringDatas: [],
    }
  },
  mounted() {
    this.getCinemaList()
  },
  methods: {
    getCinemaList() {
      const userCode = this.$store.state.userInfo.mobile
      const query = {
        "UserCode": userCode,
        "CmdIndex": "101201",
        "cmdArgs": [
          this.GetDateStr(0)
        ]
      }

      zjApi.getPs(query).then((r) => {
        const { result: data, code, Message } = r
        if (code === 200) {
          data.chainDatas.map((i) => this.cinemas.push(i))
        } else {
          Toast(Message)
        }
      })
    },
    GetDateStr(AddDayCount) {
      var dd = new Date()
      dd.setDate(dd.getDate() + AddDayCount) //获取AddDayCount天后的日期
      var y = dd.getFullYear()
      var m =
        dd.getMonth() + 1 < 10 ? '0' + (dd.getMonth() + 1) : dd.getMonth() + 1 //获取当前月份的日期，不足10补0
      var d = dd.getDate() < 10 ? '0' + dd.getDate() : dd.getDate() //获取当前几号，不足10补0
      return y + '-' + m + '-' + d
    },
    formatterDate(type, val) {
      if (type === 'year') {
        return `${val}年`
      }
      if (type === 'month') {
        return `${val}月`
      } else if (type === 'day') {
        return `${val}日`
      }
      return val
    },
    toTopPage() {
      window.history.back()
    },
    updateData(filterData) {
      this.ringDatas = []
      this.groupDatas = []
      this.filterDate = filterData.currentDate
      console.log({ filterData })
      const userCode = this.$store.state.userInfo.mobile
      const query = {
        UserCode: userCode,
        CmdIndex: "101202",
        cmdArgs: [
          filterData.province,
          filterData.cityName,
          filterData.cinemaName === '全部' ? '' : filterData.cinemaName,
          filterData.count,
          filterData.currentDate.replace('/', '-')
        ]
      }
      zjApi.getPs(query ).then((r) => {
        const {result: data, code, Message } = r
        if (code === 200) {
          data.ringDatas.map((i) => {
            this.ringDatas.push({
              const: 'const',
              filmName: i.FilmName,
              Roi: parseFloat(i.Roi),
              BoxOfficeNoService: parseFloat(i.BoxOfficeNoService),
              BoxOffice: parseFloat(i.BoxOffice),
              BoxOfficeByRate: parseFloat(i.BoxOfficeByRate),
            })
          })
          data.groupDatas.map((i) => {
            this.groupDatas.push({
              ...i,
            })
          })
        } else Toast(Message)
      })
    },
  },
}
</script>

<style scoped lang="less">
.van-nav-bar {
  background: #3d8bff;
  // 因为style标签上有 lang='less'  所以.van-nav-bar__title 可以嵌套到.van-nav-bar里面
  /* /deep/ 就是把data-v-hash值选择器写到类名的前面 */
  /deep/ .van-nav-bar__title {
    color: white;
  }
  /deep/ .van-icon,
  /deep/ .van-nav-bar__text {
    color: white;
  }
}
</style>
