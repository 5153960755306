<template>
  <div>
    <div class="address">
      <div>
        <div>省份</div>
        <div class="text-box" @click="provinceShowPicker = true">浙江省</div>
      </div>
      <div>
        <div>城市</div>
        <div class="text-box" @click="CityShowPicker = true">
          {{ filter.cityName }}
        </div>
      </div>
      <div>
        <div>所属院线</div>
        <div class="text-box" @click="showPicker = true">
          {{ filter.cinemaName }}
        </div>
      </div>
    </div>
    <div class="address">
      <div>
        <div>放映时间</div>
        <div class="text-box" @click="showDatePicker = true">
          {{ filter.currentDate }}
          <van-icon
            style="float: right; line-height: 20px"
            name="todo-list-o"
          ></van-icon>
        </div>
      </div>
      <div>
        <div>显示影片:{{ filter.count }}部</div>
        <div>
          <van-slider
            v-model="filter.count"
            @change="changeMovieCount"
            :min="1"
            :max="15"
          >
            <template #button>
              <div class="custom-button">{{ filter.count }}</div>
            </template>
          </van-slider>
        </div>
      </div>
    </div>
    <!--    省份选择器-->
    <van-popup v-model="provinceShowPicker" round position="bottom">
      <van-picker
        show-toolbar
        :columns="provinceList"
        @cancel="provinceShowPicker = false"
        @confirm="provinceShowPicker = false"
      />
    </van-popup>
    <!-- 城市选择器 -->
    <van-popup v-model="CityShowPicker" round position="bottom">
      <van-picker
        show-toolbar
        :columns="cityList"
        @cancel="CityShowPicker = false"
        @confirm="onConfirmCity"
      />
    </van-popup>
    <!-- 院线选择器 -->
    <van-popup v-model="showPicker" v-if="cinemaList" round position="bottom">
      <van-picker
        show-toolbar
        :columns="cinemaList.map((item) => item.ChainName)"
        @cancel="showPicker = false"
        @confirm="onConfirmCinema"
      />
    </van-popup>
    <!-- 时间选择器 -->
    <van-popup
      v-model="showDatePicker"
      v-if="showDatePicker"
      round
      position="bottom"
    >
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择年月日"
        :min-date="minDate"
        :max-date="maxDate"
        :formatter="formatterDate"
        @confirm="confirmDate"
      />
    </van-popup>
  </div>
</template>

<script>
export default {
  name: 'filters',
  props: {
    cinemaList: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      showPicker: false,
      CityShowPicker: false,
      provinceShowPicker: false,
      showDatePicker: false,
      provinceList: ['浙江省'],
      cityList: [
        `杭州`,
        `宁波`,
        `温州`,
        `嘉兴`,
        `湖州`,
        `绍兴`,
        `金华`,
        `衢州`,
        `舟山`,
        `台州`,
        `丽水`,
      ],
      filter: {
        province: '浙江省',
        cinemaName: this.cinemaList[0],
        cityName: '杭州',
        currentDate: '',
        count: 5,
      },
      minDate: new Date(),
      maxDate: new Date(),
      currentDate: new Date(),
    }
  },
  mounted() {
    this.minDate = new Date(this.GetDateStr(-30))
    this.maxDate = new Date(this.GetDateStr(30))
    this.currentDate = new Date(this.GetDateStr(0))
    // console.log(`${this.currentDate.getFullYear()}/${this.currentDate.getMonth()+1}/${this.currentDate.getDate()}`);
    this.filter.currentDate = `${this.currentDate.getFullYear()}-${
      this.currentDate.getMonth() + 1
    }-${this.currentDate.getDate()}`
    this.submitFilter()
  },
  methods: {
    GetDateStr(AddDayCount) {
      var dd = new Date()
      dd.setDate(dd.getDate() + AddDayCount) //获取AddDayCount天后的日期
      var y = dd.getFullYear()
      var m =
        dd.getMonth() + 1 < 10 ? '0' + (dd.getMonth() + 1) : dd.getMonth() + 1 //获取当前月份的日期，不足10补0
      var d = dd.getDate() < 10 ? '0' + dd.getDate() : dd.getDate() //获取当前几号，不足10补0
      return y + '-' + m + '-' + d
    },
    formatterDate(type, val) {
      if (type === 'year') {
        return `${val}年`
      }
      if (type === 'month') {
        return `${val}月`
      } else if (type === 'day') {
        return `${val}日`
      }
      return val
    },
    onConfirmCinema(val) {
      this.filter.cinemaName = val
      this.showPicker = false
      this.submitFilter()
    },
    onConfirmCity(val) {
      this.filter.cityName = val
      this.CityShowPicker = false
      this.submitFilter()
    },
    confirmDate(val) {
      this.showDatePicker = false
      this.currentDate = val
      // console.log(`${this.currentDate.getFullYear()}/${this.currentDate.getMonth()+1}/${this.currentDate.getDate()}`);
      this.filter.currentDate = `${this.currentDate.getFullYear()}-${
        this.currentDate.getMonth() + 1
      }-${this.currentDate.getDate()}`
      this.submitFilter()
    },
    changeMovieCount(val) {
      // this.filter.count
      console.log(val)
      this.submitFilter()
    },
    submitFilter() {
      console.log('提交筛选条件', { filter: this.filter })
      this.$emit('change', this.filter)
    },
  },
}
</script>

<style scoped lang="less">
.address {
  display: flex;
  justify-content: space-between;
  font: 1em/1.5 'DM Sans', sans-serif;

  div {
    flex: 1;
    font-size: 0.33rem;
    padding: 0.1rem;

    .text-box {
      border: 1px solid rgba(93, 93, 96, 0.63);
      border-radius: 0.1rem;
      //padding:0.1rem;
      height: 0.5rem;
      display: flex;

      > input {
        border: 1px solid red;
        display: inline-block;
      }
    }
  }
}

.custom-button {
  width: 26px;
  color: #fff;
  font-size: 10px;
  line-height: 18px;
  text-align: center;
  background-color: rgba(34, 152, 225, 0.61);
  border-radius: 100px;
}

.van-slider {
  width: 90%;
  margin: 0 auto;
  height: 0.2rem;
}
</style>
